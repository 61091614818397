import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { KegService } from 'app/services/dal/keg.service';
import { ToastService } from 'app/services/toast.service';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as KegActions from './actions'
import { Router } from '@angular/router';
import { error } from 'console';



@Injectable()
export class KegEffects
// implements OnInitEffects
{



  // ngrxOnInitEffects() {
  //   return KegActions.loadItemsFromInitEffect;
  // }



  constructor(
    private router: Router,
    private actions$: Actions,
    private kegService: KegService,
    private toastService: ToastService) { }

  getKegs$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          KegActions.loadItems,
          KegActions.loadItemsFromInitEffect
        ),
        mergeMap((action) => this.kegService.getItems(action.barId)
          .pipe(
            map(kegs => KegActions.loadItemsSuccess({ items: kegs })),
            catchError(error => of(KegActions.loadItemsFailure({ error })))
          )
        )
      );
  });


  setItemFromListPage$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(KegActions.setItemFromListPage),
          tap((action) => {
            this.router.navigate(['/kegs/details', action.id]);
          }),
        )
    },
    { dispatch: false }
  );


  updateKeg$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegActions.updateItem),
        mergeMap((action) => of(this.kegService.update(action.item))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return KegActions.updateItemSuccess({ item: action.item });
            }),
            catchError(error => of(KegActions.updateItemFailure({ error })))
          )
        )
      );
  });



  addKeg$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegActions.addItem),
        mergeMap((action) => of(this.kegService.add(action.item, action.id))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return KegActions.addItemSuccess({ item: action.item });
            }),
            catchError(error => of(KegActions.addItemFailure({ error })))
          )
        )
      );
  });


  deleteKeg$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegActions.deleteItem),
        mergeMap((action) => of(this.kegService.delete(action.id))
          .pipe(
            map(() => {
              this.toastService.deleteItemSuccess();
              return KegActions.deleteItemSuccess();
            }),
            catchError(error => of(KegActions.addItemFailure({ error })))
          )
        )
      );
  });



  deleteKegSuccess$ = createEffect(
    () => {
      return this.actions$
        .pipe(
          ofType(KegActions.deleteItemSuccess),
          tap(() => {
            this.router.navigate(['/kegs/']);
          }),
        )
    },
    { dispatch: false }
  );


  softUpdate$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegActions.softUpdate),
        mergeMap((action) => from(this.kegService.softUpdate(action.id, action.data, action.uid))
          .pipe(
            map(() => {
              this.toastService.saveSuccess();
              return KegActions.softUpdateSuccess({ id: action.id, data: action.data })
            }),
            catchError((error) => {
              return of(KegActions.softUpdateFailure({ error }))
            })
          )
        )
      );
  });

  softUpdateSuccess$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegActions.softUpdateSuccess),
        tap(() => {
          this.toastService.saveSuccess();
        }),
      );
  }, { dispatch: false });


  softUpdateFailure$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(KegActions.softUpdateFailure),
        tap(({ error }) => {
          this.toastService.error(JSON.stringify(error));
        })
      );
  }, { dispatch: false });



}
