import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { Platform, AlertController, ToastController } from "@ionic/angular";
import { AuthService } from "../../../services/auth.service";
import { IUser } from "../../../models/user";
import { UserService } from "../../../services/dal/user.service";
import { Observable, Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";
import { BarService } from "../../../services/dal/bar.service";
import { IBar } from "../../../models/bar";
//import { ToastService } from "../../../services/toast.service";
import { finalize, tap } from "rxjs/operators";
import { SubscriptionHandler } from "../../../services/subscriptionHandler.service";
import { IBarSettings } from '../../../models/barSettings';
import { BarSettingsService } from "../../../services/dal/bar-settings.service";
import { Store } from '@ngrx/store';
import * as fromBarSettings from 'app/state/bar-settings/reducer';
import * as fromBar from 'app/state/bar/reducer';
import * as fromUser from 'app/state/user/reducer';
import * as BarSettingsActions from 'app/state/bar-settings/actions';
import * as BarActions from 'app/state/bar/actions';
import { updateBar, updateBarSuccess } from '../../../state/bar/actions';
import { Update } from "@ngrx/entity";
import { User } from '@angular/fire/auth/firebase';

@Component({
  selector: "app-bar",
  templateUrl: "./bar.page.html",
  styleUrls: ["./bar.page.scss"],
})
export class BarPage implements OnInit, OnDestroy {
  @Input() isModal!: boolean;

  bar$!: Observable<IBar | null>;
  user$!: Observable<User | undefined>;
  barSettings$!: Observable<IBarSettings | undefined | null>;
  oldContent!: IBar;
  subs: Subscription[] = [];
  newApiKey = "";
  oldBarSettings!: IBarSettings;

  constructor(
    public modalController: ModalController,
    public platform: Platform,
    private barStore: Store<fromBar.State>,
    private barSettingsStore: Store<fromBarSettings.State>,
    private userStore: Store<fromUser.State>,
  ) {

  }

  async ngOnInit() {
    this.barSettings$ = this.barSettingsStore.select(fromBarSettings.selectCurrentItem);
    this.bar$ = this.barStore.select(fromBar.getBar);
    this.user$ = this.userStore.select(fromUser.getUser);

  }

  ngOnDestroy() {
    for (let index = 0; index < this.subs.length; index++) {
      const sub = this.subs[index];
      if (sub) {
        sub.unsubscribe();
      }
    }
  }

  closeModal() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  updateBar(event: IBar) {
    this.barStore.dispatch(BarActions.updateBar({ bar: event }));
  }


  updateBarSettings(event: IBarSettings) {
    if (event.id) {
      this.barSettingsStore.dispatch(BarSettingsActions.updateItem({ update: { id: event.id, changes: event } }));
    }
  }


  save(bar: IBar) {
    if (!bar.name) {
      return;
    }

    this.barStore.dispatch(BarActions.updateBar({ bar: { ...bar } }));
  }

}
