import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelper {

  constructor() { }


  convertDDMMYYYYToDate(dateString: string): Date | null {
    if (!dateString) {
      return null;
    }

    const dateArray = dateString.split('.');
    const date = new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0] + 1);
    return date;
  }


  getISOString(dateString: string): string | null {
    if (!dateString) {
      return null;
    }
    const retDate = moment(dateString).toISOString();
    if (retDate) {
      return retDate;
    }

    return moment.unix(+dateString / 1000 as any).toISOString();

  }

  removeTimeFromDate(value: any): Date {
    const dateAndTime = new Date(value);
    const dateWithoutTime = new Date(dateAndTime.toDateString());
    return dateWithoutTime;
  }

  dateInThePastWithoutTime(value: any): boolean {
    if (!value) {
      return false;
    }
    const dateWithoutTime = this.removeTimeFromDate(value);
    return dateWithoutTime <= new Date();
  }


  momentCalendarWithoutTime(value: any): string {
    return moment(value).calendar(null, {
      lastDay: '[Yesterday]',
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      lastWeek: '[Last] dddd',
      nextWeek: 'dddd',
      sameElse: 'L'
    });
  }

}
