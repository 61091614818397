import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { deleteObject, getDownloadURL, getStorage, ref, uploadString } from '@angular/fire/storage';
import { AlertController } from '@ionic/angular';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { AnySrvRecord } from 'dns';

@Component({
  selector: 'app-picture-cropper',
  templateUrl: './picture-cropper.component.html',
  styleUrls: ['./picture-cropper.component.scss'],
})
export class PictureCropperComponent implements OnChanges {

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent | undefined;

  @Input() label!: string;
  @Input() labelPosition!: string;
  @Input() barId!: string;
  @Input() pathFolder!: string;
  @Input() inputImageUrl!: string;
  @Output() formSubmitted = new EventEmitter<any>();


  imageChangedEvent: any = "";
  croppedImage: string | null | undefined = "";

  isUploadingImage!: boolean;

  imageUrl!: string;

  constructor(private alertController: AlertController) { }


  ngOnChanges() {
    this.imageUrl = this.inputImageUrl;
  }



  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  // imageLoaded(image: LoadedImage) {
  //   // show cropper
  // }

  // cropperReady() {
  //   // cropper ready
  // }
  // loadImageFailed() {
  //   // show message
  // }

  save() {
    this.isUploadingImage = true;

    this.imageChangedEvent = "";

    let croppedImgB64String: string | null | undefined = this.croppedImage;

    const timestamp = this.getTimestamp();

    const imagePath = `${this.barId}/media/logo/${timestamp}.jpg`;
    const newFilePath = this.getNewFileName(imagePath);

    // // File reference
    const storage = getStorage();
    const fileRef = ref(storage, imagePath);

    if (croppedImgB64String) {
      uploadString(fileRef, croppedImgB64String, "data_url").then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then(async (downloadURL) => {
            const newFileUrl = this.getNewFileName(downloadURL);
            await this.setImage(newFileUrl, newFilePath);

            this.formSubmitted.emit(this.imageUrl);

          })
          .finally(() => {
            this.isUploadingImage = false;
          });
      });
    }
  }

  private getNewFileName(fileName: string) {
    const lastIndex = fileName.lastIndexOf(".");
    return (
      fileName.substr(0, lastIndex) + "_2560x1440" + fileName.substr(lastIndex)
    );
  }

  private getTimestamp() {
    const current = new Date();
    return current.getTime();
  }


  private async setImage(newFileUrl: string, newFilePath: string) {
    try {
      await this.deleteOldImage(this.imageUrl);
    } catch (error) {
      console.error("delete error", error);
    }

    this.imageUrl = newFileUrl;
    this.isUploadingImage = true;
    while (this.isUploadingImage) {
      try {
        const storage = getStorage();
        const pathReference = ref(storage, newFilePath);
        await getDownloadURL(pathReference)
          .then(async (downloadURL) => {
            this.imageUrl = "";
            this.imageUrl = downloadURL;
            this.isUploadingImage = false;
          })
          .catch(async (ex) => {
            await this.delay(500);
            console.error("Image does not exist yet");
          });
      } catch (ex) {
        await this.delay(500);
        console.error("Image does not exist yet");
      }
    }
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async confirmDeleteImage() {
    const alert = await this.alertController.create({
      header: "Confirm!",
      message: "Are you sure you want to delete image?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Okay",
          handler: () => {
            this.deleteImage();
          },
        },
      ],
    });

    await alert.present();
  }

  private async deleteImage() {
    try {
      await this.deleteOldImage(this.imageUrl);
    } catch (error) {
      console.error("delete error", error);
    }
    this.imageUrl = "";
    this.formSubmitted.emit(this.imageUrl);
  }

  private async deleteOldImage(url: string) {
    if (url) {
      const storage = getStorage();
      const pathReference = ref(storage, url);
      return await deleteObject(pathReference);
    }
  }

}
