import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ISubscription } from 'app/models/subscription';
import * as AppState from '../app.state';
import * as Actions from './actions'

export interface State extends AppState.State {
  subscription?: ISubscription;
  error: string;
}

const initialState: State = {
  subscription: undefined,
  error: ''
};

export const stateName = 'subscriptions';
const getItemFeatureState = createFeatureSelector<State>(stateName);

//Selectors
export const getItem = createSelector(
  getItemFeatureState,
  state => state.subscription
);

//Selectors
export const getHasPremium = createSelector(
  getItemFeatureState,
  state => {

    const date = new Date(state.subscription?.billingPeriodEnds ?? '');
    // if (!isNaN(date.getTime())) {
    //   return false;
    // }

    return date > new Date();
  }
);


export const reducer = createReducer<State>(
  initialState,
  on(Actions.loadItemSuccess, (state, action): State => {
    return {
      ...state,
      subscription: action.item,
      error: ''
    };
  }),
  on(Actions.loadItemFailure, (state, action): State => {
    return {
      ...state,
      subscription: undefined,
      error: action.error
    };
  }),
);
